import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import SecondaryButton from "../components/Button/SecondaryButton"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";
import {Nodata, NodataMedia, NodataTitle, NodataDesc} from "../components/Nodata"
import {SectionPage, Container} from "../components/Section"
import CallToAction from "../components/CallToAction"

const FaqsPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <SEO title="404 - Not found" />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="160px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <Nodata>
            <NodataTitle className="h2">Page Not Found</NodataTitle>    
            <NodataMedia><StaticImage src="../images/error.png" alt="404" /></NodataMedia>       
            <NodataDesc mb="30px">
              <p>Sorry, we can’t find the page you are looking for click here to go back to the home page.</p>
              <Link to="/"><SecondaryButton text="Go To Home" size="md" /></Link>
            </NodataDesc>
          </Nodata>
        </Container>
      </SectionPage>
      <CallToAction />
    </Layout>
  )
}

export default FaqsPage